<script>
import filterDocumentTypes from '@/functions/document/filterDocumentTypes';

export default {
  name: 'AddFilesRow',
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    documentTypesFiltered() {
      return filterDocumentTypes(this.requiredDocument);
    },
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    number: {
      type: Number,
      default: 1,
    },
    requiredDocument: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    changedDocumentType() {
      this.$emit('input', this.value.document_type, this.number);
    },
    removeRow() {
      this.$emit('removeFileFromList');
    },
  },
};
</script>
<template>
  <tr>
    <td>{{ number + 1 }}</td>
    <td>{{ value.document.name }}</td>

    <td>
      <ValidationProvider name="document type" :vid="value.document.name" rules="required" v-slot="{ errors }">
        <select @change="changedDocumentType" class="form-control" ref="documentType" v-model="value.document_type">
          <option disabled selected value>Please Choose</option>
          <option v-for="(text, val, index) in documentTypesFiltered" :key="index" :value="val">{{ text }}</option>
        </select>
        <span v-if="errors[0]" class="form-text text-danger error" data-error-name="select-error">{{ errors[0] }}</span>
      </ValidationProvider>
    </td>

    <td class="text-right">
      <button class="btn btn-danger" @click="removeRow()"><i class="icon-bin mr-2"></i>Remove</button>
    </td>
  </tr>
</template>
