<script>
import AddFilesRow from '@/components/documentManager/AddFilesRow';
import Loader from '@/components/Loader';
import { mapActions } from 'vuex';
import uploadAtClient from '@/functions/document/uploadAtClient';

const acceptFiles = ['application/pdf', 'image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

export default {
  name: 'AddFiles',
  components: { AddFilesRow, Loader },
  data() {
    return {
      files: [], // All files to upload
      saving: false,
      acceptFiles,
    };
  },
  computed: {
    acceptFilesCSV: function () {
      return this.acceptFiles.join(',');
    },
  },
  props: {
    entityType: {
      type: String,
      default: null,
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
    requiredDocument: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ...mapActions('documents', ['addDocument']),
    handleFileSelection(selectedFiles) {
      selectedFiles.forEach((file) =>
        this.files.push({
          entity_id:
            typeof this.entity[`${this.entityType}_id`] !== 'undefined'
              ? this.entity[`${this.entityType}_id`]
              : this.entity[`${this.entityType}Id`],
          entity_type: this.entityType,
          document_type: '', // document type will be selected by user
          document_slug:
            typeof this.requiredDocument.documentSlug !== 'undefined' ? this.requiredDocument.documentSlug : '',
          metadata: {},
          document: file,
        }),
      );
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    submitFiles: async function () {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      this.saving = true;
      const sortReferId =
        typeof this.entity.sort_refer_id !== 'undefined' ? this.entity.sort_refer_id : this.entity.sortReferId;
      const uploads = await uploadAtClient(this.files, sortReferId);
      const documentManagerParams = await Promise.all(uploads);
      documentManagerParams.forEach((params) =>
        this.addDocument({
          created_at: new Date().toISOString(),
          document_name: params.document_name,
          document_type: params.document_type,
          document_slug: params.document_slug,
          entity_id: params.entity_id,
          entity_type: params.entity_type,
          metadata: params.metadata,
          id: params.id,
          error: params.error,
        }),
      );
      this.saving = false;
      this.$gtag.event('Toggle', {
        event_category: 'User',
        event_label: 'Documents Uploaded',
        value: this.files.length,
      });
      this.$emit('uploadComplete');
      this.files = [];
    },
    assignDocumentType(value, number) {
      this.files[number].document_type = value; // update value with selected value returned by event from child component
    },
  },
};
</script>

<template>
  <div>
    <ValidationObserver ref="observer">
      <div class="table-responsive" v-if="files.length > 0">
        <table class="table">
          <thead>
            <tr class="table-border-double">
              <th scope="col">#</th>
              <th scope="col">File Name</th>
              <th scope="col">Document Type</th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody>
            <template>
              <add-files-row
                :key="key"
                v-for="(file, key) in files"
                @input="assignDocumentType"
                @removeFileFromList="removeFile(key)"
                :number="key"
                :value="file"
                :requiredDocument="requiredDocument"
              >
              </add-files-row>
            </template>
          </tbody>
        </table>
        <div class="text-right">
          <button class="btn btn-success" @click="submitFiles">Upload Documents</button>
        </div>
      </div>
    </ValidationObserver>
    <legend class="text-uppercase font-size-sm font-weight-bold">Upload Documents</legend>
    <div class="dropbox">
      <input
        type="file"
        multiple
        class="input-file"
        @change="handleFileSelection($event.target.files)"
        ref="files"
        id="files"
        :accept="this.acceptFilesCSV"
      />
      <p v-if="!saving">
        Drag your file(s) here to begin<br />
        or click to browse
      </p>
      <p v-if="saving">
        <loader>Uploading {{ files.length }} files...</loader>
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
