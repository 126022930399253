export default (documents, requiredDocument) => {
  // Filter documents returned from API to show only relevant to section
  // Required document is only set for inline uploader for question where file upload is required
  // Otherwise display all uploaded files, we used that for main document manager section
  const filteredDocuments = documents.filter(({ hideFromClient = false }) => !hideFromClient);
  return typeof requiredDocument !== 'undefined' && Object.keys(requiredDocument).length !== 0
    ? filtersupportingDocuments(filteredDocuments, requiredDocument)
    : filteredDocuments;
};

function filtersupportingDocuments(documents, requiredDocument) {
  return documents.filter((obj) => {
    // Check if document has been matched with question by document_slug, if yes display file under relevant question
    const matchedByDocumentSlug = matchFileByDocumentSlug(obj.metadata, requiredDocument.documentSlug);
    if (matchedByDocumentSlug) {
      // If file has been matched by document slug -> show, otherwise match by document type if list is provided and switched on
      return true;
    } else if (
      typeof requiredDocument.documentTypes !== 'undefined' &&
      Object.keys(requiredDocument.documentTypes).length !== 0 &&
      requiredDocument.matchByDocumentTypes
    ) {
      return Object.keys(requiredDocument.documentTypes).includes(obj.documentType);
    }
    return false;
  });
}

function matchFileByDocumentSlug(fileMetadata, documentSlug) {
  const metadata = typeof fileMetadata === 'string' ? JSON.parse(fileMetadata) : fileMetadata;
  return typeof metadata.documentSlug !== 'undefined' && documentSlug !== '' && metadata.documentSlug === documentSlug;
}
