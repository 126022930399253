<script>
import AddFiles from '@/components/documentManager/AddFiles';
import Loader from '@/components/Loader';
import { mapActions, mapState } from 'vuex';
import FileRow from '@/components/documentManager/FileRow';
import Modal from '@/components/Modal';
import filterDocuments from '@/functions/document/filterDocuments';

export default {
  name: 'UploadedFilesList',
  components: { Modal, FileRow, AddFiles, Loader },
  computed: {
    ...mapState({
      documents: (state) => state.documents.documents,
      documentsLoading: (state) => state.documents.loading,
      showError: (state) => state.documents.showError,
    }),
  },
  data() {
    return {
      showFilesUploader: false,
      showDeletionWarning: false,
      showFilePropagationWarning: false,
      uploadedDocuments: {},
    };
  },
  props: {
    entityId: {
      type: String,
      default: null,
    },
    entityType: {
      type: String,
      default: null,
    },
    entity: {
      type: Object,
      default: () => ({}),
    },
    requiredDocument: {
      type: Object,
      default: () => ({}),
      true: true,
    },
  },
  methods: {
    ...mapActions('documents', ['fetchDocuments']),
    toggleFilesUploader() {
      this.showFilesUploader = !this.showFilesUploader;
    },
  },
  mounted() {
    this.fetchDocuments(this.entityId);
  },
  watch: {
    documents() {
      this.uploadedDocuments = filterDocuments(this.documents, this.requiredDocument);
    },
  },
};
</script>
<template>
  <div class="card">
    <div class="card-header bg-light header-elements-inline">
      <h3 class="card-title"><strong>Document Manager</strong></h3>
      <div class="header-elements">
        <button v-if="!showFilesUploader" type="button" class="btn btn-success mr-1" @click="toggleFilesUploader">
          <i class="icon-upload7 mr-2"></i>Add New Files
        </button>
        <button v-if="showFilesUploader" type="button" class="btn btn-danger mr-1" @click="toggleFilesUploader">
          <i class="icon-arrow-left5 mr-2"></i>Back
        </button>
      </div>
    </div>

    <!-- display uploaded files -->
    <div class="col-md-12" v-if="!showFilesUploader">
      <div
        class="row d-flex align-items-center border-top p-2"
        v-if="uploadedDocuments.length > 0 && !documentsLoading"
      >
        <div class="d-none d-md-block col-md-5">
          <div class="d-flex justify-content-center justify-content-md-start">
            <h3>File Name</h3>
          </div>
        </div>

        <div class="d-none d-md-block col-md-2">
          <div class="d-flex justify-content-center justify-content-md-start">
            <h3>Created</h3>
          </div>
        </div>

        <div class="d-none d-md-block col-md-2">
          <div class="d-flex justify-content-center justify-content-md-start">
            <h3>Document Type</h3>
          </div>
        </div>

        <div class="col-sm-12 col-md-2">
          <h4 class="d-flex justify-content-center justify-content-md-start text-nowrap">
            <span class="cursor-pointer text-info d-inline ml-md-3" @click="showFilePropagationWarning = true">
              <i class="icon-info22 mr-2 icon"></i>
              <span class="cursor-pointer">Can't view or delete?</span>
            </span>
          </h4>
        </div>
      </div>

      <div v-if="documentsLoading" class="text-center">
        <loader>Loading documents ..</loader>
      </div>

      <div v-if="showError">
        <p class="text-danger p-2">
          We encountered an error accessing your documents. Please check again later. If the issue persists, please use
          the 'Found a problem?' form located on the right hand side of the screen to get in touch. We apologise for any
          inconvenience.
        </p>
      </div>

      <div v-if="uploadedDocuments.length > 0 && !documentsLoading">
        <file-row
          @showFileProcessingInfo="showFilePropagationWarning = true"
          :key="index"
          v-for="(file, index) in uploadedDocuments"
          :file="file"
        ></file-row>
      </div>

      <div class="col-md-12" v-if="!documentsLoading && uploadedDocuments.length === 0 && !showError">
        <span class="text-muted">
          Your files and documents will appear here as and when they are created or uploaded, you don't have any yet.
        </span>
      </div>
    </div>
    <!-- /end display uploaded files -->

    <add-files
      :entityType="entityType"
      :entity="entity"
      :requiredDocument="requiredDocument"
      v-if="showFilesUploader"
      @uploadComplete="this.toggleFilesUploader"
      class="card-body"
    >
    </add-files>

    <modal v-if="showFilePropagationWarning" closeButtonText="Close" @proceed="showFilePropagationWarning = false">
      <div class="text-center">
        <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h3>Your file is processing</h3>
        <p>
          While we store your file safely you will not be able to view or delete it. This process will soon complete and
          you'll be able to view or delete your file as normal.
        </p>
      </div>
    </modal>
  </div>
</template>
