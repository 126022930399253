import auth from '@/plugins/auth/amplify';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import postUploadAtClient from '@/api/documentManager/postUploadAtClient';

let s3Client;

const instantiateS3Client = async () => {
  // Get current credentials from auth, then instantiate S3 client with them
  await auth.currentCredentials().then((credentials) => {
    s3Client = new AWS.S3({
      credentials,
      region: 'eu-west-2',
    });
  });
};

const generateMetadata = (file) => ({
  originalName: file.document.name,
  systemGenerated: 'false',
  documentSlug: file.document_slug,
  'x-amz-meta-document-type': file.document_type,
  'x-amz-meta-name': file.document.name,
});

const generateS3Params = (file, sortReferId, uuid) => ({
  Body: file.document,
  Bucket: process.env.VUE_APP_S3_BUCKET,
  Key: `${sortReferId}/${uuid}`,
  ContentType: file.document.type,
  Metadata: generateMetadata(file),
});

const generateDocumentManagerParams = (file, sortReferId, uuid) => ({
  id: uuid,
  s3_temp_file: `${sortReferId}/${uuid}`,
  document_name: file.document.name,
  document_type: file.document_type,
  entity_id: file.entity_id,
  entity_type: file.entity_type,
  metadata: JSON.stringify(generateMetadata(file)),
  error: null,
});

const uploadAtClient = async (files, sortReferId) => {
  await instantiateS3Client();
  return files.map(async (file) => {
    const key = uuidv4();
    const documentManagerParams = generateDocumentManagerParams(file, sortReferId, key);
    try {
      await s3Client.putObject(generateS3Params(file, sortReferId, key)).promise();
      await postUploadAtClient(documentManagerParams);
    } catch (err) {
      documentManagerParams.error = err.message;
    }
    return documentManagerParams;
  });
};

export default uploadAtClient;
