<script>
import previewDocument from '@/functions/document/previewDocument';
import documentTypes from '@sort-group/sort-document-types';
import Modal from '@/components/Modal';
import { mapActions } from 'vuex';

export default {
  name: 'FileRow',
  components: { Modal },
  data() {
    return {
      documentTypes,
      loadingView: false,
      deleting: false,
      showDeletionWarning: false,
      documentName: this.file?.document_name ?? this.file?.documentName,
      createdAt: this.file?.created_at ?? this.file?.createdAt,
      error: this.file?.error ?? this.file?.error,
      documentType: this.file?.document_type
        ? this.file?.document_type?.toUpperCase().split('-').join('_')
        : this.file?.documentType?.toUpperCase().split('-').join('_'),
    };
  },
  methods: {
    ...mapActions('documents', ['removeDocument']),
    loadDocumentPreview() {
      this.loadingView = true;
      previewDocument(this.file).then(() => (this.loadingView = false));
    },
    async handleDeletionModal(value) {
      this.showDeletionWarning = false;
      if (value) {
        this.deleting = true;
        await this.removeDocument(this.file.id);
        this.deleting = false;
      }
    },
    showFileProcessingInfo() {
      this.$emit('showFileProcessingInfo', true);
    },
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    systemGenerated: function () {
      const systemGenerated = this.file.metadata.systemGenerated;
      // Fix for various typeof systemGenerated value could be string/boolean
      if (typeof systemGenerated === 'string') {
        return systemGenerated === 'true';
      }
      return systemGenerated;
    },
  },
};
</script>

<template>
  <div>
    <div class="row d-flex align-items-center align-self-center border-top p-2">
      <div class="col-8 col-md-5">
        <div class="d-none d-md-inline-block mr-2">
          <button
            type="button"
            v-if="file.id !== null && !error"
            @click="loadDocumentPreview()"
            class="btn-circle btn-md btn-info border-1 btn-icon mr-3 align-middle"
          >
            <i class="icon-download7"></i>
          </button>
          <button
            type="button"
            v-else-if="error"
            class="btn-circle btn-md btn-info border-1 btn-icon mr-3 align-middle bg-danger"
          >
            <i class="icon-alert"></i>
          </button>
          <button
            type="button"
            v-else
            @click="showFileProcessingInfo()"
            class="btn-circle btn-md btn-info border-1 btn-icon mr-3 align-middle"
          >
            <i class="icon-info3"></i>
          </button>
        </div>
        <div class="d-inline-block">
          <h3 v-if="error" class="cursor-pointer text-default font-weight-semibold letter-icon-title align-middle">
            {{ documentName }}
          </h3>
          <h3
            v-else-if="file.id !== null"
            @click="loadDocumentPreview()"
            class="cursor-pointer text-default font-weight-semibold letter-icon-title align-middle"
          >
            {{ documentName }}
          </h3>
          <h3
            v-else
            @click="showFileProcessingInfo()"
            class="cursor-pointer text-default font-weight-semibold letter-icon-title align-middle"
          >
            {{ documentName }}
          </h3>
        </div>
      </div>

      <div class="col-md-2 d-none d-md-block">
        <h4 class="d-flex align-items-center justify-content-center justify-content-md-start status">
          {{ createdAt | formatDateTime }}
        </h4>
      </div>

      <div class="col-md-2 col-0 d-none d-md-block">
        <h4 class="d-flex align-items-center justify-content-center justify-content-md-start status">
          {{ documentTypes[documentType] }}
        </h4>
      </div>

      <div class="col-md-3 col-4">
        <div v-if="!error" class="d-flex align-items-end justify-content-end">
          <!-- Action buttons -->
          <button
            v-if="file.id !== null"
            type="button"
            @click="loadDocumentPreview()"
            class="btn btm-sm btn-danger bg-primary w-33 d-inline mr-1"
          >
            <i v-if="loadingView" class="spinner icon-spinner2 mr-2"></i>
            <i v-else class="icon-search4"></i><span class="ml-2 d-none d-md-inline">View</span>
          </button>
          <button
            v-if="!systemGenerated && file.id !== null"
            type="button"
            @click="showDeletionWarning = true"
            class="btn btm-sm btn-danger w-33 d-inline"
          >
            <i v-if="deleting" class="spinner icon-spinner2 mr-2"></i>
            <i v-else class="icon-bin"></i><span class="ml-2 d-none d-md-inline">Delete</span>
          </button>
          <!-- /action buttons -->
        </div>
        <div v-if="error" class="d-flex align-items-end justify-content-end" v-b-tooltip.hover.top="error">
          <p class="alert alert-warning" role="alert">Upload failed. Please try again.</p>
        </div>
      </div>
    </div>
    <div class="row d-flex p-2 d-md-none">
      <div class="col-6">
        <span :v-if="documentType !== ''"><strong>Type:</strong></span>
        {{ documentTypes[documentType] }}
      </div>

      <div class="col-6">
        <div class="d-flex align-items-end align-items-md-center justify-content-md-center justify-content-end status">
          {{ createdAt | formatDate }}
        </div>
      </div>
    </div>
    <modal
      v-if="showDeletionWarning"
      closeButtonText="Cancel"
      proceedButtonText="Delete"
      @proceed="handleDeletionModal"
    >
      <template slot="header">
        <h3 class="text-danger-400">Delete File Warning</h3>
      </template>

      <div class="text-center">
        <i class="icon-warning2 icon-2x text-danger-400 border-danger-400 border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h3>Are you sure you want to delete this file?</h3>
        <p>You will not be able to recover it!</p>
        <p class="mb-3"><b>Document Name:</b> {{ documentName }}</p>
        <p class="mb-3"><b>Document Type:</b> {{ documentTypes[documentType] }}</p>
      </div>
    </modal>
  </div>
</template>
